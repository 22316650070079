import { WorkspaceEntry } from "@/framework/workspacing/types";
import { makeObservable, toJS } from "mobx";
import { MAppEnv } from "./MAppEnv";
import { Database } from "@/lib/mobx-pouch/Database";
import { initializeDatabase } from "@/framework/app-structure/initializeDatabase";
import debug from "debug";

const log = debug("workspace:MWorkspace");

export class MWorkspace {
  database: Database;

  constructor(public mAppEnv: MAppEnv, public workspaceEntry: WorkspaceEntry) {
    log("MWorkspace constructor");
    this.database = initializeDatabase(workspaceEntry);
    makeObservable(this, {});
  }
}
