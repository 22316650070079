import { loadWorkspacesData } from "@/framework/workspacing/loadWorkspacesData";
import { WorkspaceEntry } from "@/framework/workspacing/types";
import { AppConfig } from "@/lib/nemmp/app-config/types";
import { computed, makeObservable, observable } from "mobx";
import { MWorkspace } from "./MWorkspace";
import { StatsTracker } from "@/lib/nemmp/starter/stats/StatsTracker";

export type MAppEnvOptions = {
  admin?: boolean;
};

export class MAppEnv {
  workspaceIndex = 0;
  workspaceEntries: WorkspaceEntry[] = [];
  workspaces: MWorkspace[] = [];
  statsTracker: StatsTracker;

  constructor(
    public appConfig: AppConfig,
    public options: MAppEnvOptions = {}
  ) {
    const workspacesData = loadWorkspacesData(this.appConfig);
    this.workspaceIndex = workspacesData.currentIndex;
    this.workspaceEntries = workspacesData.entries;

    this.statsTracker = new StatsTracker();

    makeObservable(this, {
      workspaceIndex: observable,
      workspaceEntries: observable,
      workspace: computed,
    });
  }

  get workspace(): MWorkspace {
    return (this.workspaces[this.workspaceIndex] ??= new MWorkspace(
      this,
      this.workspaceEntries[this.workspaceIndex]
    ));
  }
  get workspaceEntry(): WorkspaceEntry {
    return this.workspaceEntries[this.workspaceIndex];
  }
}
