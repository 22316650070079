import { WorkspaceEntry } from "@/framework/workspacing/types";
import { Database } from "@/lib/mobx-pouch/Database";
import debug from "debug";

const log = debug("pouch:initializeDatabase");

export const initializeDatabase = (
  workspaceEntry: WorkspaceEntry
): Database => {
  log(`initializeDatabase(${JSON.stringify(workspaceEntry)})`);
  const { localName, remoteDatabaseUrl } = workspaceEntry;
  const database = new Database(remoteDatabaseUrl, localName);
  return database;
};
