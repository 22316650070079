import { Router } from "@/lib/nemmp/starter/routing/Router";
import { observer } from "mobx-react";
import dynamic from "next/dynamic";
import { useEffect, useState } from "react";
import { MAppEnv } from "../workspacing/MAppEnv";
import { MAppCx } from "./MAppCx";
import { MAppFrame2Inner } from "./MAppFrame2Inner";

export const MAppFrame1Outer = observer(({ mAppEnv }: { mAppEnv: MAppEnv }) => {
  const [router, setRouter] = useState<Router | null>(null);

  // console.log(
  //   `rendering MAppFrame1Outer ${router ? "with" : "without"} router`
  // );

  useEffect(() => {
    if (typeof window !== "undefined") {
      setRouter(new Router());
    }
    mAppEnv.statsTracker.onOpen();
  }, []);

  const cx: MAppCx = {
    client: router
      ? {
          mWorkspace: mAppEnv.workspace,
          mAppEnv,
          router,
        }
      : null,
    appConfig: mAppEnv.appConfig,
  };
  return (
    <>
      <MWorkspaceDisplay cx={cx} />
    </>
  );
});

const DynamicMAppBar = dynamic(() => import("./MAppBar"), {
  loading: () => <div></div>,
});
// const DynamicMAppBar = () => <CircularProgress />;

export const MWorkspaceDisplay = observer(({ cx }: { cx: MAppCx }) => {
  // console.log(`rendering MWorkspaceDisplay`);

  return (
    <div>
      {cx.client && (
        <DynamicMAppBar cx={{ ...cx.client, appConfig: cx.appConfig }} />
      )}
      <div
        className="text-background"
        style={{
          position: "fixed",
          inset: "0 0 0 0",
          paddingTop: "64px",
        }}
      >
        <MAppFrame2Inner cx={cx} />
      </div>
    </div>
  );
});
