import { DialogSwitch } from "@/lib/nemmp/starter/routing/DialogSwitch";
import { MainScreen } from "@/modules/main/MainScreen";
import { observer } from "mobx-react";
import { useEffect, useState } from "react";
import { MAppCx } from "./MAppCx";
import { getLegacyCx } from "./getLegacyCx";
import CircularProgress from "@mui/material/CircularProgress";
import PageCenter from "@/lib/react/page-center/page-center";

// const debug = debugFactory("routing:WRouter");
const debug = console.log;

// Root element for client-side custom routing
// - listens to the popstate event and delegates to the router object

export const MAppFrame2Inner = observer(({ cx }: { cx: MAppCx }) => {
  // debug("WRouter", `updateNum:${wEnv.updateNum}`);
  const [attached, setAttached] = useState(false);

  // console.log(
  //   `rendering MWRouter ${cx.client?.router ? "with" : "without"} router`
  // );

  useEffect(() => {
    console.log(
      `running useEffect in MWRouter ${
        cx.client?.router ? "with" : "without"
      } router}`
    );
    const router = cx.client?.router;
    if (!router) return;

    const popStateHandler = (event: PopStateEvent) => {
      debug("popstate", event);
      router.onPopState(event);
    };

    if (!attached) {
      window.addEventListener("popstate", popStateHandler);
      debug("popstate in WRouter - ON");
      setAttached(true);
    }

    return () => {
      setAttached(false);
      window.removeEventListener("popstate", popStateHandler);
      debug("popstate in WRouter - OFF");
    };
  }, [cx.client?.router]);

  return <MWScreenSwitch cx={cx} />;
});

const MWScreenSwitch = observer(({ cx }: { cx: MAppCx }) => {
  // console.log(`rendering MWScreenSwitch`);

  return (
    <>
      {cx.client ? (
        <>
          <MainScreen cx={getLegacyCx(cx.client, cx.appConfig)} />
          <DialogSwitch cx={getLegacyCx(cx.client, cx.appConfig)} />
        </>
      ) : (
        <PageCenter>
          <CircularProgress />
        </PageCenter>
      )}
    </>
  );
});
