import { AppConfig } from "@/lib/nemmp/app-config";
import { StarterCx, WEnv } from "@/lib/nemmp/starter";
import { memoizeOn } from "@/lib/utils/memoize";
import { Workspace } from "@/modules/main/Workspace";
import { starterConfig } from "@/modules/starter-configs/starter-config";
import { MAppCxClient } from "./MAppCx";

export const getLegacyCx = (
  client: MAppCxClient,
  appConfig: AppConfig
): StarterCx<Workspace> => {
  return memoizeOn(client.mWorkspace, "legacyCx", () => ({
    appConfig: appConfig,
    router: client.router,
    starterConfig: starterConfig,
    wEnv: new WEnv(
      appConfig,
      client.mWorkspace.database,
      client.router,
      (wEnv: WEnv<Workspace>) => new Workspace(wEnv, client.mAppEnv.options)
    ),
  }));
};
