import { appConfig } from "@/config/app-config";
import { MAppFrame1Outer } from "@/framework/app-structure/MAppFrame1Outer";
import { MAppEnv } from "@/framework/workspacing/MAppEnv";
import type { NextPage } from "next";
import { useState } from "react";

const AppPage: NextPage = () => {
  const [mAppEnv] = useState(() => {
    return new MAppEnv(appConfig);
  });

  return (
    <div>
      <main>
        <div className="app">
          <MAppFrame1Outer mAppEnv={mAppEnv} />
        </div>
      </main>
    </div>
  );
};

export default AppPage;
